import * as actionTypes from "../constants/actionTypes";

export function serverConnected() {
    return { type: actionTypes.SERVER_CONNECTED, };
}

export function serverDisconnected() {
    return { type: actionTypes.SERVER_DISCONNECTED, };
}

export function getEvents() {
    return { type: actionTypes.GET_EVENTS_REQUEST, };
}

export function getEventsSuccess(events) {
    return { type: actionTypes.GET_EVENTS_SUCCESS, events };
}

export function checkTicket(ticketId, referenceNumber) {
    return { type: actionTypes.CHECK_TICKET_REQUEST, ticketId, referenceNumber };
}

export function checkTicketSuccess(ticket) {
    return { type: actionTypes.CHECK_TICKET_SUCCESS, ticket };
}
