import * as actionTypes from "../constants/actionTypes";

const initialState = {
    connected: false,
    events: [],
    ticket: null,
    waitTicket: false
};

export function main(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SERVER_CONNECTED: {
            return { ...state, connected: true };
        }
        case actionTypes.SERVER_DISCONNECTED: {
            return { ...state, connected: false };
        }
        case actionTypes.GET_EVENTS_SUCCESS: {
            const events = action.events;
            return { ...state, events: [...events] };
        }
        case actionTypes.CHECK_TICKET_REQUEST: {
            return { ...state, ticket: null, waitTicket: true };
        }
        case actionTypes.CHECK_TICKET_SUCCESS: {
            const ticket = action.ticket;
            return { ...state, ticket, waitTicket: false };
        }
        default: return state;
    }
}
