import { Socket } from '../utils/Socket';

let socket = null;

async function connect() {
    socket = new Socket();
    await socket.connect();
    return socket;
}

async function getEvents() {
    const message = {
        command: "getPublicResults"
    };
    const response = await socket.send(message);
    return response.events;
}

async function checkTicket(ticketId, referenceNumber) {
    const message = {
        command: "checkTicketPublic",
        ticket_id: ticketId,
        reference_number: referenceNumber
    };
    const response = await socket.send(message);
    return response;
}

export default {
    connect,
    getEvents,
    checkTicket
};
