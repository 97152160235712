import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getEvents } from "../actions/index";

import "./Results.scss";
import Ball from "./Ball";

const updateEventsInterval = 15000;

const Results = ({ connected, events, getEvents }) => {

    useEffect(() => {
        const timer = setInterval(() => {
            if (connected) {
                getEvents();
            }
        }, updateEventsInterval);
        if (connected) {
            getEvents();
        }
        return () => { clearInterval(timer); };
    }, [connected, getEvents]);

    return (
        <div className="results-wrapper">
            {events.map(function (event) {
                return (
                    <div className="event" key={event.event_id}>
                        <h3 className="event__game-title">{event.game_name}</h3>
                        <div className="event__numbers">
                            {event.results.map(function (number, j) {
                                return (
                                    <Ball key={event.event_id + "." + j} number={number} />
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );

}

function mapStateToProps(state) {
    const { connected, events } = state.main;
    return { connected, events };
}

function mapDispatchToProps(dispatch) {
    return {
        getEvents: () => dispatch(getEvents())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Results);
