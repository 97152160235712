import { eventChannel } from 'redux-saga';
import { put, all, call, fork, delay, take, takeEvery } from 'redux-saga/effects';

import * as actions from '../actions/index';
import * as actionTypes from '../constants/actionTypes';
import websocket from '../services/websocket';

const reconnectTimeout = 5000;

function* getEvents() {
    const events = yield call(websocket.getEvents);
    yield put(actions.getEventsSuccess(events));
}

function* watchGetEvents() {
    yield takeEvery(actionTypes.GET_EVENTS_REQUEST, getEvents);
}

function* checkTicket(action) {
    const ticket = yield call(websocket.checkTicket, action.ticketId, action.referenceNumber);
    yield put(actions.checkTicketSuccess(ticket));
}

function* watchCheckTicket() {
    yield takeEvery(actionTypes.CHECK_TICKET_REQUEST, checkTicket);
}

function createSocketChannel(socket) {
    return eventChannel(emitter => {
        socket.onClose = () => emitter(actions.serverDisconnected());
        return () => { }
    });
}

function* watchWebsocketEvents(timeout) {

    yield delay(timeout);

    try {
        const socket = yield call(websocket.connect);

        yield put(actions.serverConnected());
        const channel = yield call(createSocketChannel, socket);

        yield fork(function* () {
            yield take(actionTypes.SERVER_DISCONNECTED)
            channel.close();
        })

        while (true) {
            const action = yield take(channel);
            yield put(action);
        }
    } catch (e) {

    } finally {
        yield fork(watchWebsocketEvents, reconnectTimeout);
    }
}

export default function* saga() {
    yield all([
        watchGetEvents(),
        watchWebsocketEvents(0),
        watchCheckTicket()
    ]);
}
