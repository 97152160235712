import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Check from "./Check";
import Results from "./Results";

import "./App.scss";

const App = () => (<Router>
    <Switch>
        <Route path="/check">
            <Check />
        </Route>
        <Route path="/">
            <Results />
        </Route>
    </Switch>
</Router>);

export default App;
