import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import saga from "../sagas/index";
import rootReducer from "../reducers/index";

const initialiseSagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer(),
    compose(
        applyMiddleware(initialiseSagaMiddleware)
    )
);

initialiseSagaMiddleware.run(saga);

export default store;
