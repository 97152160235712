import React from 'react';
import "./Ball.scss";

export default ({ number }) => {

    const colors = ["blue", "red", "green", "yellow", "pirple"];
    const index = Math.min(Math.floor((number - 1) / 10), colors.length - 1);

    return (
        <div className={`event__numbers-ball event__numbers-ball-${colors[index]}`}>
            <div>{number}</div>
        </div>
    );

}
