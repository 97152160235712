import { host } from "../config/config";

function safeExecute(cb) {
    if (cb && typeof cb === "function") {
        cb.apply(this, [].slice.call(arguments, 1));
        return true;
    }
    return false;
}

export function Socket() {

    this.socket = null;
    this.connected = false;
    this.messageID = 0;
    this.responseHandlers = {};

    this.connect = async function () {
        this.socket = new WebSocket(host);

        return new Promise((resolve, reject) => {
            this.socket.onopen = () => {
                this.connected = true;
                safeExecute(this.onOpen);
                resolve();
            };

            this.socket.onmessage = event => {
                const message = JSON.parse(event.data);
                const id = message.message_id;
                if (id in this.responseHandlers) {
                    this.responseHandlers[id](message);
                    delete this.responseHandlers[id];
                }
            };

            this.socket.onerror = () => {
                safeExecute(this.onError);
            };

            this.socket.onclose = () => {
                if (!this.connected) {
                    reject();
                } else {
                    safeExecute(this.onClose);
                }
            };
        });
    }

    this.send = message => {
        message.message_id = ++this.messageID;
        return new Promise((resolve) => {
            this.socket.send(JSON.stringify(message));
            this.responseHandlers[this.messageID] = (response) => resolve(response);
        });
    }
};
