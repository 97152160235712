import React, { useState } from 'react';
import { connect } from 'react-redux';

import InputMask from 'react-input-mask';

import Ball from "./Ball";
import { checkTicket } from "../actions/index";

import "./Check.scss";

const Check = ({ connected, ticket, checkTicket }) => {

    const [ticketNumberValid, setTicketNumberValid] = useState(false);

    const onChange = (event) => {
        const value = event.target.value;
        if (value.length === 14) {
            const ticketId = parseInt(value.slice(0, 7));
            const referenceNumber = value.slice(8, 14);
            setTicketNumberValid(true);
            checkTicket(ticketId, referenceNumber);
        } else {
            setTicketNumberValid(false);
        }
    }

    return connected ? (
        <div className="check-wrapper">
            <div className="check-block">
                <div>To check your ticket please enter the reference number</div>
                <InputMask className="check-block__input" mask="9999999-999999" maskChar="" onChange={onChange} />
                {ticketNumberValid && ticket &&
                    <div className="check-block__results">
                        {ticket.found ? (
                            <>
                                {ticket.completed ? (
                                    <>
                                        <div>Event Numbers</div>
                                        <div className="event__numbers">
                                            {ticket.event_numbers.map(function (number, i) {
                                                return (
                                                    <Ball key={i} number={number} />
                                                );
                                            })}
                                        </div>
                                    </>) : (
                                        <>
                                            <div className="check-block__wait-icon"></div>
                                            <div style={{opacity: "0.7"}}>
                                                Results are not processed yet. Please try again later.
                                            </div>
                                        </>)
                                }
                                <div style={{ marginTop: "18px" }}>Ticket Numbers</div>
                                <div className="event__numbers">
                                    {ticket.ticket_numbers.map(function (number, i) {
                                        return (
                                            <Ball key={i} number={number} />
                                        );
                                    })}
                                </div>
                                <div className="check-block__ticket" style={{ marginTop: "18px" }}>
                                    <div>Game</div>
                                    <div>{ticket.game}</div>
                                    <div>Bet</div>
                                    <div>{`${(ticket.amount / 100).toFixed(2)} (${ticket.bet_type_label})`}</div>
                                    {ticket.completed ? (
                                        <>
                                            <div>Win</div>
                                            <div>{(ticket.win / 100).toFixed(2)}</div>
                                            {ticket.paid && ticket.status > 0 ? (<div style={{ fontWeight: "600", color: "rgb(45 253 45)", gridColumn: "1/3", textAlign: "center" }}>TICKET PAID</div>) : (<></>)}
                                            {ticket.status === 0 ? (<div style={{ fontWeight: "600", color: "rgb(253 45 45)", gridColumn: "1/3", textAlign: "center" }}>TICKET CANCELLED</div>) : (<></>)}
                                        </>) : (<></>)
                                    }
                                </div>
                            </>
                        ) : (<div>Ticket not found</div>)}
                    </div>
                }
            </div>
        </div>
    ) : null;

}

function mapStateToProps(state) {
    const { connected, ticket } = state.main;
    return { connected, ticket };
}

function mapDispatchToProps(dispatch) {
    return {
        checkTicket: (ticketId, referenceNumber) => dispatch(checkTicket(ticketId, referenceNumber))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Check);
